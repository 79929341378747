@use '@dashboard-experience/mastodon/lib/theme.scss';

#mastodon {
  .reports-list-actions-flex-container {
    display: flex;
    flex-direction: row;
    gap: 12px;

    @media (max-width: 1055px) {
      flex-direction: column;
    }

    margin-bottom: 0.5rem;
    background-color: yellow;
  }

  .reports-list-actions-search-bar {
    .mastodon-text-input {
      margin-bottom: 0;
    }

    width: 280px;
    align-self: flex-start;

    @media (max-width: 1055px) {
      width: 100%;
    }
  }
  .reports-list-actions-flex-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;
    width: 100%;

    @media (max-width: 671px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .reports-list-actions-left {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .reports-list-actions-right {
    display: flex;
    gap: 12px;

    @media (max-width: 671px) {
      flex-direction: row-reverse;
    }
  }

  .reports-list-actions-button-container {
    .mastodon-button.secondary {
      padding: 6px 10px;
    }

    .mastodon-button.secondary:focus:enabled {
      border: 1px solid #e1e6eb;
      box-shadow: 0 0 0 0 !important;
    }
  }

  .reports-list-actions-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
  .reports-list-actions-menu {
    padding: 8px 14px;
  }

  .reports-list-actions-divider {
    width: 2px;
    height: 20px;
    background-color: blue;
  }

  .reports-list-actions-save-button {
    .mastodon-button.secondary {
      background-color: theme.$ui-green-600;
      color: white;
      border: 1px solid theme.$ui-green-600 !important;

      svg {
        fill: white;
      }
    }

    .mastodon-button.secondary:hover:enabled:not(.disabled),
    .mastodon-button.secondary:active:enabled {
      background-color: theme.$ui-green-700 !important;
      border: 1px solid theme.$ui-green-800 !important;
    }
  }
}
